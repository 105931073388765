import axiosInstance from "auth/FetchInterceptor";
import {
  CHANGE_GRAGE_BRAND,
  LIST_PARTNER_STORE_CRM,
  PARNER_SEARCH_GRID,
  PARTNER_API,
  PARTNER_CHANGE_PRESTIGE,
  PARTNER_CHANGE_STATUS_API,
  PARTNER_REGISTER_API,
  PARTNER_REGISTER_VERIFY_API,
} from "constants/ApiConstant";
const PartnerService = {};

PartnerService.partnerSearchGrid = function (data) {
  return axiosInstance.post(PARNER_SEARCH_GRID, data);
};

PartnerService.partnerChangeStatus = function (data) {
  return axiosInstance.post(PARTNER_CHANGE_STATUS_API, data);
};

PartnerService.partnerRegister = function (data) {
  return axiosInstance.post(PARTNER_REGISTER_API, data);
};

PartnerService.partnerRegisterVerify = function (data) {
  return axiosInstance.post(PARTNER_REGISTER_VERIFY_API, data);
};

PartnerService.changePrestigePartnersApi = function (data) {
  return axiosInstance.put(PARTNER_CHANGE_PRESTIGE, data);
};
PartnerService.delPartner = function (data) {
  return axiosInstance.post(`${PARTNER_API}/delete`, data);
};
PartnerService.getListPartnerStoreCrm = function () {
  return axiosInstance.get(LIST_PARTNER_STORE_CRM, null);
};
PartnerService.changeGradeBrandApi = function (data) {
  console.log("data",data);
  return axiosInstance.post(CHANGE_GRAGE_BRAND, data);
};

export default PartnerService;