export const API_BASE_URL = "https://your-api-url.com/";

const PREFIX = "/en/api/v1.0";

export const GET_PROFILE = `${PREFIX}/User/profile`;
export const ACCOUNT_LOGIN = `${PREFIX}/User/login`;
export const ACCOUNT_LOGIN_V2 = `${PREFIX}/User/login-pos`;
export const SIGN_OUT = `${PREFIX}/User/logout`;
export const VERIFY_ACCOUNT = `${PREFIX}/User/verify-password`;
export const GET_ACCOUNT_POS = `${PREFIX}/Mobile/get-account-pos`;
export const FORGOT_PASSWORD = `${PREFIX}/User/password/forgot-by-email`;
export const RESET_PASSWORD = `${PREFIX}/User/password/reset-by-email`;
export const SEARCH_BY_ROLES = `${PREFIX}/User/search-by-roles`;
export const REFRESH_TOKEN_API = `${PREFIX}/TokenManager/refresh-token`;
export const UPDATE_PROFILE = `${PREFIX}/User/profile`;
export const CHANGE_PASS_PROFILE = `${PREFIX}/User/change-pass-profile`;
export const SEARCH_BY_ROLES_REQUEST = `${PREFIX}/User/user-by-roles-request`;

const SETTING_API = `${PREFIX}/Setting`;
export const SETTING_STORE_CATEGORY_API = `${SETTING_API}/store-categories`;
export const SETTING_MENU_CATEGORY_API = `${SETTING_API}/menu-categories`;
export const SETTING_STORE_UTILITY_API = `${SETTING_API}/store-utility`;
export const SETTING_PRODUCT_ATTRIBUTE = `${SETTING_API}/product-attribute-categories`;
export const SETTING_BANNER = `${SETTING_API}/banner`;
export const SETTING_TERM_CONDITION = `${SETTING_API}/system-setting/term-condition`;
export const SETTING_PRIVACY = `${SETTING_API}/system-setting/privacy`;

export const PARTNER_API = `${PREFIX}/Partner`;
export const PARNER_SEARCH_GRID = `${PARTNER_API}/search-grid`;
export const PARTNER_CHANGE_STATUS_API = `${PARTNER_API}/change-status`;
export const PARTNER_REGISTER_API = `${PARTNER_API}/register`;
export const PARTNER_REGISTER_VERIFY_API = `${PARTNER_API}/register/verify`;
export const PARTNER_CHANGE_PRESTIGE = `${PARTNER_API}/change-prestige`;
export const LIST_PARTNER_STORE_CRM = `${PARTNER_API}/list-partner-store-crm`;
export const CHANGE_GRAGE_BRAND = `${PARTNER_API}/change-grade-brand`;

const LOCATION_API = `${PREFIX}/Location`;
export const PROVINCE_BY_COUNTRY = `${LOCATION_API}/province-by-country`;
export const DISTRICT_BY_PROVINCE = `${LOCATION_API}/district-by-province`;
export const WARD_BY_DISTRICT = `${LOCATION_API}/ward-by-district`;

const ORDER_API = `${PREFIX}/Order`;
export const SEARCH_ORDER = `${ORDER_API}/search-grid`;
export const EXPORT_EXEL_ORDER = `${ORDER_API}/export-exel`;
export const SEARCH_ORDER_DETAIL = `${ORDER_API}/detail`;
export const ORDER_CHANGE_STATUS_ALL = `${ORDER_API}/order-item/change-all`;
export const ORDER_ADD_ITEM = `${ORDER_API}/add-item`;
export const ORDER_CONFIRM = `${ORDER_API}/confirm`;
export const ORDER_REJECT = `${ORDER_API}/reject`;
export const ORDER_PAYMENT_CONFIRM = `${ORDER_API}/payment-confirm`;
export const ORDER_PAYMENT_REQUEST = `${ORDER_API}/payment-request`;
export const ORDER_CANCEL_ITEM = `${ORDER_API}/cancel-item`;
export const ORDER_CHANGE_STATUS = `${ORDER_API}/order-item/change-status`;
export const ORDER_UPDATE_EMPLOYEE = `${ORDER_API}/update-order-infor`;
export const ORDER_CREATE_EMPLOYEE = `${ORDER_API}/employee/create`;
export const ORDER_EXTRACT = `${ORDER_API}/extract`;
export const ORDER_GROUP = `${ORDER_API}/group`;
export const ORDER_ITEM_PRINT_STATUS = `${ORDER_API}/order-item/update-order-print`;
export const TABLE_SEARCH_ORDER = `${ORDER_API}/table/search`;
export const TABLE_SEARCH_EMPLOYEE_ORDER = `${ORDER_API}/employee/search`;
export const TABLE_SEARCH_EMPLOYEE_ORDER_V2 = `${ORDER_API}/employee/search-order`;
export const TABLE_SUMMARY_EMPLOYEE_ORDER = `${ORDER_API}/employee/summary-order`;
export const ORDER_ITEM_ADD_EXTERNAL= `${ORDER_API}/order-item/add-external`;
export const ORDER_ITEM_UPDATE_EXTERNAL= `${ORDER_API}/order-item/update-external`;
export const ORDER_CHANGE_CASH = `${ORDER_API}/change-cash`;
export const ORDER_PRE_PAYMENT = `${ORDER_API}/pre-payment`;
export const UPDATE_ORDER_PAID = `${ORDER_API}/update-order-paid`;
export const TABLE_SEARCH_PRODUCT_ORDER = `${ORDER_API}/employee/search-product`;
export const PRODUCT_ITEM_UPDATE_ALL= `${ORDER_API}/product-item/change-all`;
export const SORT_ORDER_ITEMS = `${ORDER_API}/sort-items`;
export const STORE_PRODUCT = `${PREFIX}/Mobile/store/product-by-category`;
export const CHANGE_ORDER_TYPE = `${PREFIX}/Mobile/setting-order`;

export const STORE_API = `${PREFIX}/Store`;
export const STORE_SEARCH_GRID = `${STORE_API}/search-grid`;
export const GET_MY_STORE = `${STORE_API}/my-store`;
export const STORE_CHANGE_STATE = `${STORE_API}/change-state`;

export const STORE_WIFI_API = `${PREFIX}/StoreWifi`;
export const STORE_TABLE_API = `${PREFIX}/StoreDinnerTable`;
export const STORE_TABLE_API_CREATE_MULTI = `${STORE_TABLE_API}/add-multi`;

export const PRODUCT_API = `${PREFIX}/Product`;
export const MOBILE_PRODUCT_DETAIL = `${PREFIX}/Mobile/product/Detail`;
export const PRODUCT_SEARCH_GRID = `${PRODUCT_API}/search-grid`;
export const PRODUCT_CHANGE_STATE = `${PRODUCT_API}/change-state`;
export const PRODUCTS_COPY = `${PRODUCT_API}/copy-product`;
export const PRODUCTS_GROUP_CATEGORY = `${PRODUCT_API}/group-category`;
export const PRODUCTS_CHANGE_STATUS = `${PRODUCT_API}/change-status`;
export const PRODUCTS_DISCOUNT = `${PRODUCT_API}/products-discount`;

export const CUSTOMER_API = `${PREFIX}/User`;
export const CUSTOMER_SEARCH_GRID = `${CUSTOMER_API}/customer-search-grid`;
export const CUSTOMER_APP_SEARCH_GRID = `${CUSTOMER_API}/customer-app-search-grid`;

export const EMPLOYEE_API = `${PREFIX}/User`;
export const EMPLOYEE_CREATE = `${EMPLOYEE_API}/create-user`;
export const EMPLOYEE_UPDATE = `${EMPLOYEE_API}/update-user`;
export const EMPLOYEE_CHANGE_STATUS = `${EMPLOYEE_API}/change-status`;
export const SEARCH_EMPLOYEE = `${EMPLOYEE_API}/search-employee`;

const REPORT_API = `${PREFIX}/Report`;
export const REPORT_REVENUE = `${REPORT_API}/report-revenue`;
export const REPORT_TOP_PRODUCT = `${REPORT_API}/report-top-product`;
export const REPORT_STATUS_ORDER = `${REPORT_API}/report-status-order`;
export const REPORT_TOP_EMPLOYEE = `${REPORT_API}/report-top-employee`;
export const REPORT_CHART_REVENUE = `${REPORT_API}/report-chart-revenue`;
export const REPORT_ACCOUNT_REVENUE = `${REPORT_API}/report-account-revenue`;
export const REPORT_ACCOUNT_CHART_REVENUE = `${REPORT_API}/report-account-chart-revenue`;

const NOTIFICATION_API = `${PREFIX}/Notification`;
export const SEARCH_NOTIFICATION = `${NOTIFICATION_API}/search`;
export const READ_ALL_NOTIFICATION = `${NOTIFICATION_API}/read-all`;
export const DEL_ALL_NOTIFICATION = `${NOTIFICATION_API}/delete-all`;
export const SAVE_FIREBASE_TOKEN_NOTIFICATION = `${NOTIFICATION_API}/save-firebase-token`;
export const READ_NOTIFICATION = `${NOTIFICATION_API}/mark-read`;
export const SAVE_LABEL_STAR_NOTIFICATION = `${NOTIFICATION_API}/save-label-star`;
export const DEL_MULTI_NOTIFICATION = `${NOTIFICATION_API}/delete-multi`;

export const CUSTOMER_ACCOUNT_DELETE = `${PREFIX}/User/delete`;
export const CUSTOMER_ACCOUNT_CHANGE_STATUS = `${PREFIX}/User/change-status`;
export const CUSTOMER_ACCOUNT_CHANGE_GREEN_SCORE = `${PREFIX}/User/change-green-score`;

export const POST_API = `${PREFIX}/Post`;
export const SEARCH_POST = `${POST_API}/search-grid`;
export const DELETE_POST = `${POST_API}/delete`;
export const CLONE_POST = `${POST_API}/clone`;
export const COUNT_TOTAL_VIEW_POST = `${POST_API}/count-total-view`;

export const SHOPPING_CART = `${PREFIX}/ShoppingCart`;
export const SHOPPING_CART_TABLE = `${SHOPPING_CART}/employee/table-cart`;
export const SHOPPING_CART_SORT = `${SHOPPING_CART}/sort-items`;
export const EMPLOYEE_UPSERT_SHOPPING_CART = `${SHOPPING_CART}/employee/up-sert`;
export const EMPLOYEE_UPSERT_EXTERNAL_SHOPPING_CART = `${SHOPPING_CART}/employee/up-sert-external`;
export const UPSERT_SHOPPING_CART = `${SHOPPING_CART}/up-sert`;
export const DELETE_CART = `${SHOPPING_CART}/delete-cart`;
export const CHANGE_QUANTITY = `${SHOPPING_CART}/change-quantity`;
export const CHANGE_CUSTOMER = `${SHOPPING_CART}/change-customer`;
export const CHANGE_TABLE = `${SHOPPING_CART}/change-table`;

export const POST_COMMENT_API = `${PREFIX}/PostComment`;
export const SEARCH_POST_COMMENT = `${POST_COMMENT_API}/root-comments`;
export const ADD_POST_COMMENT = `${POST_COMMENT_API}/add-comment`;
export const UPDATE_POST_COMMENT = `${POST_COMMENT_API}/update-comment`;
export const LIKE_POST_COMMENT = `${POST_COMMENT_API}/like-comment`;
export const DEL_POST_COMMENT = `${POST_COMMENT_API}/delete`;
export const REPLY_POST_COMMENT = `${POST_COMMENT_API}/reply-comment`;
export const GET_REPLY_POST_COMMENT = `${POST_COMMENT_API}/replies-comment`;
export const TOGGLE_HIDE_POST_COMMENT = `${POST_COMMENT_API}/toggle-hide-comment`;


const PREFIX_V2 = "/en/api/v2.0";
const REPORT_API_V2 = `${PREFIX_V2}/Report`;
export const REPORT_REVENUE_V2 = `${REPORT_API_V2}/report-revenue`;
export const REPORT_TIME_V2 = `${REPORT_API_V2}/report-time`;

// SInvoice API;
const PREFIX_INVOICE = `${PREFIX}/StoreInvoice`;
export const GET_STORE_INVOICE = `${PREFIX_INVOICE}/detail-invoice`;
export const UPSET_STORE_INVOICE = `${PREFIX_INVOICE}/up-sert-invoice`;
export const GET_ORDER_INVOICE = `${PREFIX_INVOICE}/order-invoice`;
export const GENERATE_INVOICE = `${PREFIX_INVOICE}/generate-invoice`;
export const UPSET_ORDER_INVOICE = `${PREFIX_INVOICE}/up-sert-order-invoice`;
export const SIGN_ORDER_INVOICE = `${PREFIX_INVOICE}/sign-invoice`;
export const GET_CUSTOMER_INVOICE = `${PREFIX_INVOICE}/customer-invoice`;

const PREFIX_SINVOICE = "/services/einvoiceapplication/api/InvoiceAPI";
export const LOGIN_INVOICE = "/auth/login";
export const GET_ALL_TEMPLATE_INVOICE = `${PREFIX_SINVOICE}/InvoiceUtilsWS/getAllInvoiceTemplates`;
export const CREATE_USB_HASH_INVOICE = `${PREFIX_SINVOICE}/InvoiceWS/createInvoiceUsbTokenGetHash`;
export const SIGNATURE_USB_HASH_INVOICE = `${PREFIX_SINVOICE}/InvoiceWS/createInvoiceUsbTokenInsertSignature`;
export const CREATE_HSM_INVOICE = `${PREFIX_SINVOICE}/InvoiceWS/createInvoice`;
export const CREATE_PDF_INVOICE = `${PREFIX_SINVOICE}/InvoiceWS/createExchangeInvoiceFile`;
export const GET_INVOICE_UUID = `${PREFIX_SINVOICE}/InvoiceWS/searchInvoiceByTransactionUuid`;