import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SettingService from "services/SettingService";

export const getStoreUtility = createAsyncThunk(
  "setting/getStoreUtility",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.GetAllStoreUtility();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  utilityList: [],
};
export const StoreUtilitySlice = createSlice({
  name: "storeUtility",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStoreUtility.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStoreUtility.fulfilled, (state, action) => {
        state.loading = false;
        state.utilityList = action.payload;
      })
      .addCase(getStoreUtility.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading } = StoreUtilitySlice.actions;

export default StoreUtilitySlice.reducer;
