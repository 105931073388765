import axiosInstance from "auth/FetchInterceptor";
import {
  SETTING_STORE_CATEGORY_API,
  SETTING_MENU_CATEGORY_API,
  SETTING_STORE_UTILITY_API,
  SETTING_PRODUCT_ATTRIBUTE,
  SETTING_BANNER,
  SETTING_PRIVACY,
  SETTING_TERM_CONDITION,
} from "constants/ApiConstant";
const SettingService = {};

//Store Category
SettingService.GetAllStoreCate = function () {
  return axiosInstance.get(SETTING_STORE_CATEGORY_API, null);
};
SettingService.AddStoreCate = function (data) {
  return axiosInstance.post(SETTING_STORE_CATEGORY_API, data);
};
SettingService.UpdateStoreCate = function (data) {
  return axiosInstance.put(SETTING_STORE_CATEGORY_API, data);
};
SettingService.DeleteStoreCate = function (id) {
  return axiosInstance.delete(`${SETTING_STORE_CATEGORY_API}/${id}`, null);
};

//Store Menu Category
SettingService.GetAllMenuCate = function () {
  return axiosInstance.get(SETTING_MENU_CATEGORY_API, null);
};
SettingService.AddMenuCate = function (data) {
  return axiosInstance.post(SETTING_MENU_CATEGORY_API, data);
};
SettingService.UpdateMenuCate = function (data) {
  return axiosInstance.put(SETTING_MENU_CATEGORY_API, data);
};
SettingService.DeleteMenuCate = function (id) {
  return axiosInstance.delete(`${SETTING_MENU_CATEGORY_API}/${id}`, null);
};

//Store Utility
SettingService.GetAllStoreUtility = function () {
  return axiosInstance.get(SETTING_STORE_UTILITY_API, null);
};

//Product Attribute
SettingService.getProductAttribute = function () {
  return axiosInstance.get(SETTING_PRODUCT_ATTRIBUTE, null);
};

SettingService.GetStoreBanner = function () {
  return axiosInstance.get(SETTING_BANNER, null);
};
SettingService.AddStoreBanner = function (data) {
  return axiosInstance.post(SETTING_BANNER, data);
};
SettingService.UpdateStoreBannerState = function (data) {
  return axiosInstance.put(`${SETTING_BANNER}/change-status`, data);
};
SettingService.UpdateStoreBanner = function (data) {
  return axiosInstance.put(SETTING_BANNER, data);
};
SettingService.DeleteStoreBanner = function (id) {
  return axiosInstance.delete(`${SETTING_BANNER}/${id}`, null);
};

// System setting
SettingService.GetTermCondition = function () {
  return axiosInstance.get(SETTING_TERM_CONDITION, null);
};
SettingService.GetPrivacy = function () {
  return axiosInstance.get(SETTING_PRIVACY, null);
};
SettingService.GetBankList = function () {
  return axiosInstance.get('/v2/banks', {baseURL: process.env.REACT_APP_VIETQR});
};
export default SettingService;
