import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  ACCOUNT_INVOICE,
  AUTH_TOKEN,
  AUTH_TOKEN_INVOICE,
  MENU_SORT,
  MENU_TYPE,
  REFRESH_TOKEN,
  USER_ROLES
} from "constants/AuthConstant";
import FirebaseService from "services/FirebaseService";
import AuthService from "services/AuthService";
import { setAuthenData } from "utils/helper";
import { cloneDeep } from "lodash";
import openNotification from "utils/notification";
import Utils from "utils";

export const SystemRole = {
  OperatorAdmin: 1,
  PartnerAdmin: 10,
  StoreAdmin: 11,
  StoreEmployee: 12,
  KitchenEmployee: 13,
  AccountantEmployee: 14,
  EndUser: 20,
};

export const initialState = {
  profile: null,
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN) || null,
  roleList: [],
  userRoles: localStorage.getItem(USER_ROLES) || [],
  forgotPw: {},
  roleListrequest: [],
  accountLists: [],
};

export const getProfileApi = createAsyncThunk(
  "auth/profile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthService.getProfileApi();
      if (response.data) {
        return response.data;
      } else {
        return rejectWithValue(response.message?.replace("Firebase: ", ""));
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const signIn = createAsyncThunk(
  "auth/signIn",
  async (data, { rejectWithValue }) => {
    const { email, password } = data;
    try {
      const response = await AuthService.login({
        emailOrPhone: email,
        password: password,
      });
      if (response.data) {
        const payload = {
          token: response.data.tokenData.token,
          userRoles: response.data.userRoles,
        };
        setAuthenData(response.data);
        // const token = response.data.tokenData.token;
        return payload;
      } else {
        return rejectWithValue(response.message?.replace("Firebase: ", ""));
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const verifyAccount = createAsyncThunk(
  "auth/verifyAccount",
  async (data, { rejectWithValue }) => {
    const { phoneNumber, password, onSuccess } = data;
    try {
      const response = await AuthService.verifyAccount({
        phone: phoneNumber,
        password: password,
      });
      if (response.data) {
        if (onSuccess) onSuccess(response.data);
        return response.data;
      } else {
        return rejectWithValue(response.message?.replace("Firebase: ", ""));
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getEmployeeAccount = createAsyncThunk(
  "auth/getEmployeeAccount",
  async (data, { rejectWithValue }) => {
    const { phoneNumber, onSuccess } = data;
    try {
      const response = await AuthService.getAccountEmployee(phoneNumber);
      if (response.data) {
        if (onSuccess) onSuccess(response.data);
        return response.data;
      } else {
        return rejectWithValue(response.message?.replace("Firebase: ", ""));
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const signInEmployee = createAsyncThunk(
    "auth/signIn",
    async (data, { rejectWithValue }) => {
      try {
        const response = await AuthService.loginEmployee(data);
        if (response.data) {
          const payload = {
            token: response.data.tokenData.token,
            userRoles: response.data.userRoles,
          };
          setAuthenData(response.data);
          // const token = response.data.tokenData.token;
          return payload;
        } else {
          return rejectWithValue(response.message?.replace("Firebase: ", ""));
        }
      } catch (err) {
        return rejectWithValue(err.message || "Error");
      }
    }
);

export const signUp = createAsyncThunk(
  "auth/signUp",
  async (data, { rejectWithValue }) => {
    const { email, password } = data;
    try {
      const response = await FirebaseService.signUpEmailRequest(
        email,
        password
      );
      if (response.user) {
        const token = response.user.refreshToken;
        localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
        return token;
      } else {
        return rejectWithValue(response.message?.replace("Firebase: ", ""));
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const forgotPasswordApi = createAsyncThunk(
  "auth/forgotPassword",
  async (data, { rejectWithValue }) => {
    try {
      data.secretKey = process.env.REACT_APP_SECRETKEY;
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await AuthService.forgotPassword(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const ResetPasswordApi = createAsyncThunk(
  "auth/resetPasswordApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthService.resetPassword(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const signOutApi = createAsyncThunk(
  "auth/signOutApi",
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem(AUTH_TOKEN);
      const refreshToken = localStorage.getItem(REFRESH_TOKEN);
      const response = await AuthService.signOutApi({ token, refreshToken });
      localStorage.removeItem(REFRESH_TOKEN);
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(MENU_SORT);
      localStorage.removeItem(MENU_TYPE);
      localStorage.removeItem(AUTH_TOKEN_INVOICE);
      localStorage.removeItem(ACCOUNT_INVOICE);
      return response.data;
    } catch (err) {
      // return rejectWithValue(err.message || "Error");
      return;
    }
  }
);

export const signOut = createAsyncThunk("auth/signOut", async () => {
  const response = await FirebaseService.signOutRequest();
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(MENU_SORT);
  localStorage.removeItem(MENU_TYPE);
  localStorage.removeItem(AUTH_TOKEN_INVOICE);
  localStorage.removeItem(ACCOUNT_INVOICE);
  return response.data;
});

export const signInWithGoogle = createAsyncThunk(
  "auth/signInWithGoogle",
  async (_, { rejectWithValue }) => {
    const response = await FirebaseService.signInGoogleRequest();
    if (response.user) {
      const token = response.user.refreshToken;
      localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
      return token;
    } else {
      return rejectWithValue(response.message?.replace("Firebase: ", ""));
    }
  }
);

export const signInWithFacebook = createAsyncThunk(
  "auth/signInWithFacebook",
  async (_, { rejectWithValue }) => {
    const response = await FirebaseService.signInFacebookRequest();
    if (response.user) {
      const token = response.user.refreshToken;
      localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
      return token;
    } else {
      return rejectWithValue(response.message?.replace("Firebase: ", ""));
    }
  }
);

export const searchByRoles = createAsyncThunk(
  "auth/searchByRoles",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthService.searchByRoles(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const updateProfile = createAsyncThunk(
  "auth/updateProfile",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await AuthService.updateProfile(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const changePassProfile = createAsyncThunk(
  "auth/changePassProfile",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await AuthService.changePassProfile(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const searchByRolesRequest = createAsyncThunk(
  "auth/searchByRolesRequest",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthService.searchByRolesRequest();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = "/";
      state.token = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.redirect = "/";
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },
    setForgotPw: (state, action) => {
      state.forgotPw = action.payload;
    },
    setProfile: (state) => {
      state.loading = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload.token;
        state.userRoles = action.payload.userRoles;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithGoogle.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithGoogle.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithFacebook.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithFacebook.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithFacebook.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getProfileApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfileApi.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.profile = action.payload;
      })
      .addCase(getProfileApi.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(forgotPasswordApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgotPasswordApi.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
      })
      .addCase(forgotPasswordApi.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOutApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(signOutApi.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        // state.userRoles = [];
        // state.token = '';
      })
      .addCase(signOutApi.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(searchByRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchByRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.roleList = action.payload;
      })
      .addCase(searchByRoles.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(updateProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(searchByRolesRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchByRolesRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.roleListrequest = action.payload;
      })
      .addCase(searchByRolesRequest.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getEmployeeAccount.fulfilled, (state, action) => {
        state.accountLists = action.payload;
      })
      .addCase(getEmployeeAccount.rejected, (state) => {
        state.accountLists = [];
      })
      .addCase(verifyAccount.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(verifyAccount.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
  setForgotPw,
  setProfile
} = authSlice.actions;

export default authSlice.reducer;
